import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername, formatUserData } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import RegistrationContext from '../../../contexts/registrationContext';

interface ILoginHeaderProps {
  isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
  return (
    <>
      <div className='text-center h1 fw-bold mt-5'>Bonjour,</div>
      <div className='text-center h4 text-muted mb-5'>Connectez vous pour continuer!</div>
    </>
  );
};
LoginHeader.defaultProps = {
  isNewUser: false,
};

interface ILoginProps {
  isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { setUserData, setUserName, userData } = useContext(AuthContext);
  const { getAllRegistrations } = useContext(RegistrationContext);

  const { darkModeStatus } = useDarkMode();

  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/'), [navigate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: userData.email || "",
      loginPassword: userData.password || "",
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = 'Required';
      }

      if (!values.loginPassword) {
        errors.loginPassword = 'Required';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      if (values.loginUsername) {
        try {
          console.log(process.env);
          const response = await (
            await fetch(process.env.REACT_APP_API_URL || '', {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                operationName: 'authUser',
                query: `
mutation authUser($email: String!, $password: String!, $origin: String!) {
	authUser (email: $email, password: $password, origin: $origin) {
		token
	}
}
`,
                variables: {
                  email: values.loginUsername,
                  password: values.loginPassword,
                  origin: 'argos',
                },
              }),
            })
          ).json();
          localStorage.setItem('token', response.data.authUser.token);
          if (response.data.authUser.token) {
            const responseMe = await (
              await fetch(process.env.REACT_APP_API_URL || '', {
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                  authorization: response.data.authUser.token,
                },
                body: JSON.stringify({
                  operationName: 'getUserMe',
                  query: `
query getUserMe{
	getUserMe{
        email,
        isAdmin,
        name,
        firstname,
        createdBy,
        isActive,
        registrationDate,
        lastLogin,
		uuid,
        phoneNumber
		pp,
    }
}`,
                }),
              })
            ).json();

            if (setUserName) {
              setUserName(
                `${responseMe.data.getUserMe.firstname} ${responseMe.data.getUserMe.name}`,
              );
              if (setUserData) {
                const formatedUser = formatUserData(responseMe.data.getUserMe);
                setUserData(formatedUser);
                await getAllRegistrations();
              }
            }
            handleOnClick();
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleContinue = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (!formik.values.loginUsername) {
        formik.setFieldError('loginUsername', 'No such user found in the system.');
      } else {
        setSignInPassword(true);
      }
      setIsLoading(false);
    }, 1000);
  };

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? 'Sign Up' : 'Login'}
      className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center my-5'>
                  <Link
                    to='/'
                    className={classNames('text-decoration-none  fw-bold display-2', {
                      'text-dark': !darkModeStatus,
                      'text-light': darkModeStatus,
                    })}
                    aria-label='Argos'>
                    <Logo width={200} />
                  </Link>
                </div>

                <LoginHeader isNewUser={singUpStatus} />
                <form className='row g-4'>
                  {singUpStatus ? (
                    <>
                      <div className='col-12'>
                        <FormGroup id='signup-email' isFloating label='Your email'>
                          <Input type='email' autoComplete='email' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup id='signup-name' isFloating label='Your name'>
                          <Input autoComplete='given-name' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup id='signup-surname' isFloating label='Your surname'>
                          <Input autoComplete='family-name' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup id='signup-password' isFloating label='Password'>
                          <Input type='password' autoComplete='password' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <Button color='info' className='w-100 py-3' onClick={handleOnClick}>
                          Sign Up
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-12'>
                        <FormGroup
                          id='loginUsername'
                          isFloating
                          label='Your email or username'
                          className={classNames({
                            'd-none': signInPassword,
                          })}>
                          <Input
                            autoComplete='username'
                            value={formik.values.loginUsername}
                            isTouched={formik.touched.loginUsername}
                            invalidFeedback={formik.errors.loginUsername}
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => {
                              formik.setErrors({});
                            }}
                          />
                        </FormGroup>
                        {signInPassword && (
                          <div className='text-center h4 mb-3 fw-bold'>
                            Bonjour, {formik.values.loginUsername}
                          </div>
                        )}
                        <FormGroup
                          id='loginPassword'
                          isFloating
                          label='Password'
                          className={classNames({
                            'd-none': !signInPassword,
                          })}>
                          <Input
                            type='password'
                            autoComplete='current-password'
                            value={formik.values.loginPassword}
                            isTouched={formik.touched.loginPassword}
                            invalidFeedback={formik.errors.loginPassword}
                            validFeedback='Looks good!'
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        {!signInPassword ? (
                          <Button
                            color='warning'
                            className='w-100 py-3'
                            isDisable={!formik.values.loginUsername}
                            onClick={handleContinue}>
                            {isLoading && <Spinner isSmall inButton isGrow />}
                            Continue
                          </Button>
                        ) : (
                          <Button
                            color='warning'
                            className='w-100 py-3'
                            onClick={formik.handleSubmit}>
                            Login
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              </CardBody>
            </Card>
            <div className='text-center'>
              <a
                href='/'
                className={classNames('text-decoration-none me-3', {
                  'link-light': singUpStatus,
                  'link-dark': !singUpStatus,
                })}>
                Privacy policy
              </a>
              <a
                href='/'
                className={classNames('link-light text-decoration-none', {
                  'link-light': singUpStatus,
                  'link-dark': !singUpStatus,
                })}>
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
