import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { DELETE_REGISTRATION, GET_ALL_REGISTRATIONS, UPDATE_REGISTRATION } from './graphql/registrations';

export type User = {
  email: string;
  isAdmin: boolean;
  name: string;
  firstname: string;
  createdBy: string;
  isActive: boolean;
  registrationDate: string;
  lastLogin: string;
  uuid: string;
  status: string;
  pp: string;
  phoneNumber: string;
};
export type IRegistration = {
  _id: string;
  createdBy: string;
  customerInfos: CustomerInfos;
  payementvalue: number;
  signature: string;
  active: boolean;
  createdAt: string;
  assoId: string;
  missionId: string;
  newsletter: boolean;
  rd: User;
  commentary: string;
  status: string;
};

type CustomerInfos = {
  name: string;
  firstname: string;
  email: string;
  gender: string;
  phonenumber: string;
  address: string;
  birthdate: string;
  bankaccount: BankAccount;
};
type BankAccount = {
  iban: string;
  bic: string;
};

export interface IRegistrationContextProps {
  setRegistrationsData?(...args: unknown[]): unknown;
  registrationsData: Partial<IRegistration[]>;
  getAllRegistrations: () => Promise<boolean>;
  updateRegistration: (registrationInput: IRegistration) => Promise<Boolean>;
  deleteRegistration: (registrationInput: IRegistration) => Promise<Boolean>
}
const RegistrationContext = createContext<IRegistrationContextProps>(
  {} as IRegistrationContextProps,
);

interface IRegistrationContextProviderProps {
  children: ReactNode;
}
export const RegistrationContextProvider: FC<IRegistrationContextProviderProps> = ({
  children,
}) => {
  const [registrationsData, setRegistrationsData] = useState<Partial<IRegistration[]>>([]);

  async function deleteRegistration(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'deleteRegistration',
            query: DELETE_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  async function updateRegistration(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'updateRegistration',
            query: UPDATE_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function getAllRegistrations() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'listAllRegistrations',
            query: GET_ALL_REGISTRATIONS,
            variables: {
              searchOptions: {
                status: ['waiting', 'approuved', 'cancelled'],
              },
            },
          }),
        })
      ).json();
      setRegistrationsData(response?.data?.listAllRegistrations);
      return true;
    } catch (err) {
      return false;
    }
  }

  const value = useMemo(
    () => ({
      registrationsData,
      setRegistrationsData,
      getAllRegistrations,
      updateRegistration,
      deleteRegistration
    }),
    [registrationsData],
  );
  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};
RegistrationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RegistrationContext;
