export const CREATE_ASSOCIATION = `
    mutation CreateAssociation($associationName: String!) {
        createAssociation(associationName: $associationName) {
            _id
            name
        }
    }
`;

export const GET_ASSOCIATION = `
    query GetAssociation($getAssociationId: String!) {
        getAssociation(id: $getAssociationId) {
            _id
            name
            missions
        }
    }
`;

export const LIST_ASSOCIATIONS = `
    query ListAllAssociations {
        listAllAssociations {
            _id
            name
            missions
        }
    }
`;

export const DELETE_ASSOCIATION = `
    mutation {
        deleteAssociation($associationInput: AssociationInput!) {
            deleteAssociation(associationInput: $associationInput)
        }
    }
`;
