import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { RegistrationContextProvider } from './contexts/registrationContext';
import { ReportingContextProvider } from './contexts/reportingContext';
import { AssociationContextProvider } from './contexts/associationsContext';

const children = (
  <Router>
    <AuthContextProvider>
      <ThemeContextProvider>
        <RegistrationContextProvider>
          <AssociationContextProvider>
            <ReportingContextProvider>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </ReportingContextProvider>
          </AssociationContextProvider>
        </RegistrationContextProvider>
      </ThemeContextProvider>
    </AuthContextProvider>
  </Router>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
